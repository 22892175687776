.optionsMenuButton {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  border: solid 1px rgba(33, 50, 59, 0.2);
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
  z-index: 99999;
}

.optionsMenu {
  position: absolute;
  right: 35px;
  top: 5px;
  text-align: center;
  cursor: pointer;
  background-color: white;
  padding: 5px;
  border: solid 1px rgba(33, 50, 59, 0.2);
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
  z-index: 99999;
}

.optionsMenu > button {
  margin: 2px;
}
