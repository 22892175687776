.contextMenu {
  position: absolute;
  z-index: 99999;
  background-color: white;
  box-shadow: 0px 0px 2px black;
  font-size: 12px;
}

.contextMenu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.contextMenu ul li {
  padding: 5px;
  cursor: pointer;
}

.contextMenu div {
  padding: 5px;
  font-weight: bolder;
}

.contextMenu ul li:hover {
  background-color: rgb(200 204 221);
}
