.deckContainer {  
  position: absolute;
  user-select: none;
  color: white;
  background-color: gray;
  display: flex;
  cursor: default;
}

.deckContainer > div {
  padding-right: 5px;
  cursor: pointer;
}

.cardText {
  position: absolute;  
}
