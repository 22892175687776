.movable {
  position: absolute;
  cursor: grab;
  user-select: none;
}

.movable:active {
  cursor: grabbing;
}

.movable:focus {
  outline: none;
}
